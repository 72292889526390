import { CSSProperties } from '@stitches/react'

import { DataTableProps } from '../components/DataTable/base'

/* eslint-disable camelcase */
export enum ContractSettleType {
  CoinMargin,
  USDTMargin,
  Spot,
}

export enum ContractType {
  Swap,
  Future,
  Spot,
}

export type FxRate = {
  currency: string
  rate: number
}

export type Index = {
  exchange: string
  bid: string
  ask: string
  bidEditable: string
  askEditable: string
  indexBid: string
  indexAsk: string
  use: boolean
  sortId: number
}

export type FundingRateAggregation = {
  symbol: string
  oneDays: number | null
  threeDays: number | null
  tenDays: number | null
  thirtyDays: number | null
}

export type FutureDeliveryInfo = {
  symbol: string
  deliveryDate: string
  left: string
}

// type for future or swap instruments
export type FutureSwap = {
  instrumentId: string
  exchange: string | null
  exchange_raw: string | null
  common_id: string | null
  id: string | null
  bidSize: number
  bid: number
  ask: number
  askSize: number
  target: string | null
  futPremiumBid: string | null
  futPremiumAsk: string | null
  taker: number
  minSize: number
  rawBid: number
  rawAsk: number
  h24Volume: string | null
  openInterest: string | null
  timestamp: string | null
  currRate: string | null
  estRate: string | null
  type: string | null
  sortId: number
  insuranceBalance: string | null
  apy: string | null
}

export type ExchangeServiceStatus = {
  exchangeName: string
  isConnected: boolean
}

export type AllStableExData = {
  exchange: string
  bid: number | null
  ask: number | null
  volume24H: number | null
}

export type AllStableEx = {
  alias: string
  commonId: string
  settle: ContractSettleType
  type: ContractType
  datas: AllStableExData[]
}

export type SimpleEarnData = {
  product: string
  exchange: string
  period: string
  apy: number
  hasBonus: boolean
  bonus: number
  updateTime: number
}

export type AllSimpleEarnEx = {
  alias: string
  commonId: string
  data: SimpleEarnData[]
}

export type DerivativeExData = {
  curAPY: number | null
  estAPY: number | null
  estimatedRate: number | null
  exchange: string
  fundingRate: number | null
  oneDays: number | null
  openInterset: number | null
  prem: number | null
  tenDays: number | null
  thirtyDays: number | null
  threeDays: number | null
  volume24H: number | null
  askPrice?: number | null
  bidPrice?: number | null
  absolute: number | null
  isSwap: boolean
  percentileData: PercentileData[]
  percentileDays: number
  percentilePrem: PercentilePercentData
  percentileApr: PercentilePercentData
}
export type PercentilePercentData = {
  [key: string]: number
}
export type PercentileData = {
  exchange: string
  symbol: string
  days: number
  apr: PercentilePercentData
  prem: PercentilePercentData
  [key: string]: number | string | PercentilePercentData
}

export type Percentile = {
  alias: string
  commonId: string
  datas: PercentileData[]
  timestamp: number
}

export type DerivativeEx = {
  alias: string
  commonId: string
  settle: ContractSettleType
  type: ContractType
  datas: DerivativeExData[]
}

export type DerivativeExV2 = {
  alias: string
  commonId: string
  settle: ContractSettleType
  type: ContractType
  datas: Record<string, DerivativeExData | null>
}

export type OpenInterestChange = {
  decreaseChange: number
  decreaseInstrumentName: string
  increaseChange: number
  increaseInstrumentName: string
}

export type LendingRateInfo = {
  currentRate: string
  estRate: string
  fifteenDays: string
  sevenDays: string
  symbol: string
  thirtyDays: string
  threeDays: string
}

export type DefiApyInfo = {
  symbol: string
  apy: string
  timestamp: string
}

// can be spot or future price
export type CoinPrice = {
  ask_price: number
  ask_price_raw: number
  ask_size: number
  bid_price: number
  bid_price_raw: number
  bid_size: number
  common_id: string
  currency: string
  exchange: string
  exchange_raw: string
  filter_type: number
  fixedCurrency: string
  h24Volume: string
  id: string
  is_real_timestamp: boolean
  maker_commission: number
  min_filtersize: number
  sortId: number
  taker_commission: number
  timestamp: string
  type: string
}

export type CoinPairInfo = {
  ask: string
  askSize: string
  bid: string
  bidSize: string
  ccy: string
  common_id: string
  currency: string
  exchange: string
  exchange_raw: string
  id: string
  maker: number
  minSize: number
  raw_ask: string
  raw_bid: string
  sortId: number
  taker: number
  timestamp: string
  type: string
}

export type TokenSupplyInfo = {
  currency: string
  dataType: string
  totalSupply: string
}

export type TableMetaConfig<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TStreamData extends Record<'tabName' | string, unknown> = any
> = {
  type:
    | 'FutureDelivery'
    | 'FundingRate'
    | 'FutureSwap'
    | 'FuturePrem'
    | 'Index'
    | 'FxRate'
    | 'LendingRate'
    | 'DefiApy'
    | 'CoinPrice'
    | 'CoinPriceFuture'
    | 'CoinPairInfo'
    | 'TokenSupplyInfo'
    | 'AllStableEx'
    | 'DerivativeEx'
    | 'DerivativeExApy'
    | 'DerivativeExV2'
    | 'OpenInterestChange'
    | 'SimpleEarnEx'
  dataKey: (keyof Omit<TStreamData, 'tabName'> & string) | null // key to get data from stream data, null means original data
  transformData?: (data: any) => any
  tabeleHeader?: React.FC<TStreamData>
  // this props will be passed to the table component directly
  tableProps:
    | (Partial<Pick<DataTableProps<any>, 'title' | 'rowKey'>> &
        Record<string, unknown>)
    | null
  tablePropsExtractor?: (row: TStreamData) => Record<string, unknown>
  tab: string | number | null
  gridArea?: string
}

export type ExchangeDetailsConfig<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TStreamData extends Record<'tabName' | string, unknown> = any
> = {
  title: string
  dataCategory: string
  tabs: {
    defaultValue: string
    labels: string[]
    gridProperties?: Record<
      string,
      {
        gridTemplateAreas: CSSProperties['gridTemplateAreas']
        gridTemplateColumns: CSSProperties['gridTemplateColumns']
      }
    >
  }
  tables: TableMetaConfig<TStreamData>[]
}
